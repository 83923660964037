<template>
    <div class="border-main white member-card p-p-2 p-m-2">
        <h3 class="member-tag p-text-center text-white main p-pb-1 p-pt-2">
            {{ tag }}
        </h3>
        <div class="image-box p-d-flex p-jc-center p-align-center p-mt-2 border-main">
            <img :src="imgSrc" alt="no-pic">
        </div>
        <h3 class="text-main p-text-center p-m-2">{{ name }}</h3>
        <a class="p-d-block p-mb-1" :href="`mailto:${email}`">
            <img class="p-d-block mail-icon p-mx-auto" src="/images/mail.png" alt="mail-icon">
        </a>
    </div>
</template>

<script>
    export default {
        name: 'MemberCard',
        props: {
            tag: String,
            imgSrc: String,
            name: String,
            email: String
        }
    }
</script>

<style scoped lang="scss">
.member-card {
    max-width: 200px;
    .image-box {
        overflow: hidden;
        background-color: #efefef;
        img {
            width: 180px;
        }
    }
    .mail-icon {
        width: 30px;
        height: 30px;
    }
}
</style>