<template>
    <div>
        <div
            v-for="(info, index) in infos"
            :key="`${info.tag}-${index}`"
            class="member-row"
        >
            <h2 class="text-main p-mt-0 p-mb-5 p-ml-2">{{ info.tag }}</h2>
            <div class="p-d-flex p-flex-wrap">
                <member-card
                    v-for="(member, index) in info.members"
                    :key="`member-${index}`"
                    :tag="member.tag"
                    imgSrc="/images/member-nopic.svg"
                    :name="member.name"
                    :email="member.email"
                ></member-card>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted } from "vue";
    import MemberCard from "@/components/members/MemberCard";
    import { apiExecutor } from "@/api";

    export default {
        name: 'Members',
        components: { MemberCard },
        setup() {
            const tagsMap = {
                a: 'Alumni' ,
                p: 'Ph. D.' ,
                g: 'Graduate' ,
                s: 'Undergraduate'
            };

            let infos = ref([]);

            const replaceTags = function(members) {
                return members.map((member) => {
                    member.tag = tagsMap[member.tag];
                    return member;
                });
            };

            const categorizeMembers = function (members) {
                let alumnus = [], phds = [], graduates = [], undergraduates = [];

                alumnus = { tag: 'AlumnusPh. D. students', members: members.filter((member) => member.tag === tagsMap.a) };
                phds = { tag: 'Ph. D. students', members: members.filter((member) => member.tag === tagsMap.p) };
                graduates = { tag: 'Graduate students', members: members.filter((member) => member.tag === tagsMap.g) };
                undergraduates = { tag: 'Undergraduate students', members: members.filter((member) => member.tag === tagsMap.s) };

                return [ phds, graduates, undergraduates, alumnus ];
            };

            onMounted(async () => {
                let members = await apiExecutor.getMembers();
                members = replaceTags(members);
                infos.value = categorizeMembers(members);
            });

            return { infos };
        }
    }
</script>

<style scoped lang="scss">
    h2 {
        font-size: 48px;
    }

    .member-row {
        margin-bottom: 100px;
    }
</style>